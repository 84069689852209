import React, { useState, useEffect } from "react"
import {Modal,Form} from 'react-bootstrap'
import {InDetail} from '../Shared/InputControl'
import {actions} from '../Config/ar'
import {ModalHeader,ModalFooter} from '../Shared/ModalControl'
import Api, {Get, Post} from '../Api'

const EvalModal = ({modal, onHide, Message}) => {
  const entity = "Evaluation"
  const action = "Comment"
  const [loaded, Load] = useState(false)
  const [items, Items]= useState([])

useEffect(() => {
    //console.log('EvalModal',modal,data); alert(`EvalModal M:${data.member} P:${data.planned}`)
    if(!loaded) {
      Items([])
      const request=`/${entity}/${modal.member}-${modal.planned}/${action}`
      Get(request, Items, Load, Message)
    }
  }, [modal, loaded])  


 const onChange = (e,item) => {
  e.preventDefault()
  //alert(`update ${e.keyCode} - ${e.shiftKey} - ${e.target.value} - id:${item.Id} - ${item.Back}`)
  if (e.keyCode === 13) {
    if (e.shiftKey) {
      e.stopPropagation()
      const comment = e.target.value.substring(0, e.target.value.length-1).trim()
      e.target.value = comment
      
      Api.post(entity, {action, id:item.Id, comment})
      .then(response => {
        Message && Message(response.data)
        Load(false)
      })
      .catch(error => {
          Message && Message(error)
      })
      //Post(entity, {action, id:item.Id, comment}, Message, Load)
    }
    else {
      Message("? Shift + Enter للحفظ")
    }
  }  
}


return (

<div className="outlet">
  <Modal
    show={modal.show}
    onHide={onHide}
    size="lg"
    aria-labelledby = "التقييم"
    animation={false}
    centered
  >
    <ModalHeader title={`تقييم ${items[0]?.Name} : ${items[0]?.Title}`} />

    <Modal.Body style={{ width:"100%", height:"65vh", display: 'flex', justifyContent: 'center' }}>
                 
      <Form id="EvalModalForm" style={{width:'90%'}}>

      {items.map(item=>
        
        <InDetail name={`Q-${item.QuestionId}`} label={item.Content} size={"2rem"}
          onKeyUp={e=>onChange(e,item)} value={item.Comment} back={item.Back} />
      )}
      </Form>
     </Modal.Body>

      <ModalFooter onHide={onHide} label={"الرجوع"}/>
  </Modal>

    </div>
  )
}
export default EvalModal

/**
 * <Form onSubmit={onSubmit} 
 * <OnSubmit label="تأكيد" crud="3" />
 * </Form>
 */